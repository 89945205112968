import { useStaticQuery, graphql } from 'gatsby'

export const useRandomPages = number => {
  const { wordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query {
        wordpressWpApiMenusMenusItems(slug: { eq: "main_nav" }) {
          items {
            title
            url
            type
            object_slug
          }
        }
      }
    `
  )

  let random1 = Math.floor(
    Math.random() * wordpressWpApiMenusMenusItems.items.length
  )
  let random2 = null

  do {
    random2 = Math.floor(
      Math.random() * wordpressWpApiMenusMenusItems.items.length
    )
  } while (random1 === random2)

  return [
    wordpressWpApiMenusMenusItems.items[random1],
    wordpressWpApiMenusMenusItems.items[random2],
  ]
}
