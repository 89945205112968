import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Hamburger from '../components/Hamburger'
import './../styles/styles.scss'

export default ({ children, slug }) => {
  const toggleMenu = (e) => {
    console.log('toggle!')
    let body = document.body

    if (body.classList.contains('nav_open')) {
      body.classList.remove('nav_open')
    } else {
      body.classList.add('nav_open')
    }
  }

  const scrollMe = () => {
    console.log('scrollme')
    if (window.location.hash) {
      const elmnt = document.getElementById(window.location.hash.substring(1))
      console.log(elmnt)
      if (elmnt != null) {
        setTimeout(function() {
          elmnt.scrollIntoView(true)
        }, 200)
      }
    }
  }

  if (
    typeof navigator !== 'undefined' &&
    navigator.userAgent.indexOf('Trident/') !== -1
  ) {
    document.body.classList.add('msie')
  }

  useEffect(() => {
    // Met à jour le titre du document via l’API du navigateur
  })

  return (
    <>
      <Helmet>
        <title>Rapport annuel RSRL 2023</title>
        <meta
          name="description"
          content="Rapport annuel 2023 du Réseau Santé Région Lausanne (RSRL)."
        />
      </Helmet>{' '}
      <Header title="Rapport annuel 2023">
        <Hamburger
          clickEvent={(e) => {
            e.preventDefault()
            toggleMenu(e)
          }}
        />{' '}
      </Header>{' '}
      <Navbar toggleMenu={toggleMenu} /> <div> {children} </div>{' '}
      <Footer slug={slug} />{' '}
    </>
  )
}
