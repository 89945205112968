import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(slug: { eq: "menu-reseaux-sociaux" }) {
          items {
            url
            object_slug
          }
        }
      }
    `}
    render={({ wordpressWpApiMenusMenusItems }) => (
      <div className="social-nav">
        {wordpressWpApiMenusMenusItems.items.map((item, index) => (
          <a
            key={index}
            href={item.url}
            target="_blank"
            className={`icon-${item.object_slug}`}
          >
            {item.object_slug === 'youtube' && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 20.1">
                <path
                  d="M27.3 3.5c-.3-1.2-1.2-2.1-2.4-2.4C22.8.5 14.2.5 14.2.5s-8.5 0-10.7.6c-1.1.3-2 1.2-2.3 2.4-.8 4.3-.8 8.8 0 13.1.3 1.2 1.2 2.1 2.4 2.4 2.1.6 10.7.6 10.7.6s8.5 0 10.7-.6c1.2-.3 2.1-1.2 2.4-2.4.7-4.3.7-8.8-.1-13.1zM11.5 14.1V6l7.1 4.1-7.1 4z"
                  fill="#fff"
                />
                <path
                  fill="#9c175d"
                  d="M11.5 14.6c-.1 0-.2 0-.2-.1-.2-.1-.2-.3-.2-.4V6c0-.2.1-.3.2-.4.2-.1.3-.1.5 0l7.1 4.1c.1 0 .2.2.2.3s-.1.3-.2.4l-7.1 4.1c-.1.1-.2.1-.3.1zm.5-7.8v6.4l5.6-3.2L12 6.8z"
                />
                <path
                  fill="#9c175d"
                  d="M14.2 20.1c-.4 0-8.6 0-10.8-.6-1.3-.4-2.4-1.4-2.8-2.8-.8-4.5-.8-8.9 0-13.3C1 2 2.1.9 3.5.6 5.6 0 13.9 0 14.2 0s8.6 0 10.8.6c1.3.4 2.4 1.4 2.8 2.8.8 4.4.8 8.9 0 13.3-.4 1.4-1.4 2.4-2.8 2.8-2.1.6-10.4.6-10.8.6zm0-19.1c-.1 0-8.5 0-10.5.6-1 .3-1.8 1.1-2.1 2.1-.7 4.3-.7 8.6 0 12.9.3 1 1 1.7 2 2 2 .5 10.4.6 10.5.6s8.5 0 10.5-.6c1-.3 1.8-1.1 2.1-2.1.7-4.2.7-8.6 0-12.9-.3-1-1.1-1.8-2.1-2C22.7 1 14.3 1 14.2 1zm-2.7 13.6c-.1 0-.2 0-.2-.1-.2-.1-.2-.3-.2-.4V6c0-.2.1-.3.2-.4.2-.1.3-.1.5 0l7.1 4.1c.1 0 .2.2.2.3s-.1.3-.2.4l-7.1 4.1c-.1.1-.2.1-.3.1zm.5-7.8v6.4l5.6-3.2L12 6.8z"
                />
              </svg>
            )}
            {item.object_slug === 'facebook' && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.3 20.1">
                <path
                  d="M15.6 19.6v-8.7h2.9l.5-3.4h-3.4V5.3c0-1 .3-1.7 1.7-1.7h1.8v-3c-.3 0-1.4-.1-2.6-.1-2.6 0-4.4 1.6-4.4 4.5v2.5H9.2v3.4h2.9v8.7h3.5z"
                  fill="#fff"
                />
                <path
                  d="M15.6 20.1h-3.5c-.3 0-.5-.2-.5-.5v-8.2H9.2c-.3 0-.5-.2-.5-.5V7.5c0-.3.2-.5.5-.5h2.4V5c0-3.1 1.9-5 4.9-5 1.3 0 2.4.1 2.7.1.2 0 .4.2.4.5v3c0 .3-.2.5-.5.5h-1.8c-1.1 0-1.2.4-1.2 1.2V7H19c.1 0 .3.1.4.2.1.1.1.3.1.4l-.5 3.3c0 .2-.2.4-.5.4h-2.4v8.2c0 .4-.2.6-.5.6zm-3-1h2.5v-8.2c0-.3.2-.5.5-.5h2.5l.3-2.4h-2.8c-.3 0-.5-.2-.5-.5V5.3c0-.9.2-2.2 2.2-2.2h1.3v-2c-.5-.1-1.3-.1-2.1-.1-2.4 0-3.9 1.5-3.9 4v2.5c0 .3-.2.5-.5.5H9.7v2.4h2.4c.3 0 .5.2.5.5v8.2z"
                  fill="#9c175d"
                />
              </svg>
            )}
            {item.object_slug === 'twitter' && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.3 20.1">
                <path
                  d="M25.2 3.4L23.6 5c-.2.2-.3.3-.3.6 0 1.6-.2 3.2-.8 4.8-.5 1.5-1.2 2.9-2.2 4.2-1.1 1.4-2.3 2.5-3.9 3.4-2.5 1.4-5.1 1.9-7.9 1.6-2.1-.2-4-.9-5.8-1.9 0 0-.1-.1-.2-.1 2.6.2 4.8-.4 6.9-2-2.2-.2-3.6-1.3-4.4-3.3.4 0 .7.1 1 0 .3 0 .7-.1 1-.1-2.3-.8-3.5-2.3-3.7-4.8.7.3 1.3.6 2.1.6-.8-1-1.5-1.9-1.7-3.1-.3-1.2-.1-2.4.5-3.5 2.6 3 5.8 4.7 9.7 5 0-.5-.1-.9-.1-1.3.1-2 1.3-3.7 3.2-4.4 1.8-.6 3.4-.2 4.7 1.1.2.2.4.2.6.2.8-.2 1.6-.5 2.3-.9.1-.1.2-.1.3-.2C24.6 2 24 2.8 23 3.5c.9-.1 1.7-.3 2.5-.6-.1.2-.2.4-.3.5z"
                  fill="#fff"
                />
                <path
                  d="M9.9 20.1c-.5 0-.9 0-1.4-.1-2.1-.2-4.1-.9-6-2 0 0-.1-.1-.2-.1-.2-.1-.3-.4-.2-.6s.3-.4.5-.3c2.1.2 3.9-.2 5.6-1.2-1.7-.5-2.9-1.6-3.6-3.4-.1-.2 0-.3.1-.5.1-.1.3-.2.4-.2-1.4-1-2.1-2.4-2.2-4.4 0-.2.1-.3.2-.4.1-.1.3-.1.5 0 .2-.1.3 0 .4 0-.4-.6-.7-1.2-.8-1.9-.3-1.3-.1-2.6.5-3.8.1-.2.3-.3.4-.3.2 0 .3 0 .4.2C7 3.9 9.8 5.5 13.4 5.9v-.8C13.5 2.9 14.9 1 17 .3c1.9-.6 3.7-.2 5.2 1.2.8-.2 1.5-.5 2.2-.9l.3-.2c.2-.1.4-.1.5.1.2.1.2.3.2.5-.2.6-.4 1.1-.8 1.6.3-.1.5-.2.8-.3.2-.1.4 0 .6.2.1.2.1.4 0 .6l-.1.2c-.1.2-.2.3-.4.5l-1.6 1.6c-.1.1-.1.1-.1.2 0 1.7-.2 3.4-.8 4.9-.5 1.6-1.3 3.1-2.3 4.3-1.1 1.5-2.5 2.6-4 3.5-2.1 1.2-4.3 1.8-6.8 1.8zm-5.3-2.2c1.3.6 2.7 1 4.1 1.1 2.8.2 5.3-.3 7.6-1.6 1.4-.8 2.7-1.9 3.7-3.2.9-1.2 1.6-2.5 2.1-4s.7-3 .7-4.6c0-.5.2-.7.4-.9.3-.3.6-.6.9-.8-.3.1-.7.1-1 .2-.2 0-.4-.1-.5-.3-.1-.2 0-.5.2-.6.5-.3.8-.7 1.1-1.1-.5.2-1 .3-1.5.5-.5.1-.8-.1-1-.3-1.2-1.1-2.7-1.5-4.2-1-1.7.5-2.8 2.1-2.9 3.9V6.4c0 .1 0 .3-.1.4s-.2.1-.3.1c-3.8-.3-7-1.8-9.5-4.6-.4.8-.4 1.6-.2 2.5.2 1 .8 1.9 1.7 2.6.2.1.3.4.2.6-.1.2-.3.3-.5.3-.6 0-1.1-.1-1.5-.3.3 1.8 1.4 2.9 3.2 3.5.2.1.4.3.3.5 0 .2-.2.4-.4.5-.3.1-.7.1-1.1.1h-.3c.8 1.4 2 2.2 3.6 2.3.2 0 .4.2.4.4.1.2 0 .4-.2.5-1.4 1.3-3.1 2-5 2.1z"
                  fill="#9c175d"
                />
              </svg>
            )}
          </a>
        ))}
      </div>
    )}
  />
)
