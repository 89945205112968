import React from 'react'

import SocialMenu from '../components/SocialMenu'
import FooterMenu from '../components/FooterMenu'
import { Link } from 'gatsby'

import { useRandomPages } from '../uses/useRandomPages'
import { useWordPressPages } from '../uses/useWordPressPages'

const TransversalNav = ({ pages, slug }) => {
  if (slug === '') {
    return <React.Fragment />
  }

  return (
    <section className="transversal-nav">
      <div className="transversal-title">Pour aller plus loin</div>
      <div className="transversal-article-wrapper">
        {pages.map((page, index) => (
          <article className="transversal-article" key={index}>
            <Link to={`/${page.object_slug}`}>
              <h2 dangerouslySetInnerHTML={{ __html: page.title }} />
            </Link>
          </article>
        ))}
      </div>
    </section>
  )
}

const PrevNextNav = ({ slug }) => {
  const ids = [9, 15, 7, 13, 11, 17, 19, 21]
  const pages = useWordPressPages()

  const wordpressPages = ids.map((id, index) => {
    return pages.find(page => page.wordpress_id == id)
  })

  const currentIndex = wordpressPages.findIndex(page => page.slug == slug)

  const prevIndex =
    currentIndex <= 0 ? wordpressPages.length - 1 : currentIndex - 1
  const nextIndex =
    currentIndex >= wordpressPages.length - 1 ? 0 : currentIndex + 1

  return (
    <div className="prev-next-nav">
      <Link to={`/${wordpressPages[prevIndex].slug}`}>
        <svg
          fill="#9b005b"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15.8 30.3"
        >
          <polygon points="15.1 30.3 0 15.1 15.1 0 15.8 .7 1.4 15.1 15.8 29.5" />
        </svg>
      </Link>
      <Link to={`/${wordpressPages[nextIndex].slug}`}>
        <svg
          fill="#9b005b"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15.8 30.3"
        >
          <polygon points=".7 30.3 0 29.5 14.4 15.1 0 .7 .7 0 15.8 15.1" />
        </svg>
      </Link>
    </div>
  )
}

export default ({ slug }) => {
  const pages = useRandomPages(2)
  return (
    <>
      <TransversalNav pages={pages} slug={slug} />
      <footer>
        <SocialMenu />
        <PrevNextNav slug={slug} />
        <FooterMenu />
      </footer>
    </>
  )
}
