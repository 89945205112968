import { useStaticQuery, graphql } from 'gatsby'

export const useWordPressPages = () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query {
        allWordpressPage {
          nodes {
            type
            title
            slug
            path
            featured_media {
              id
              source_url
            }
            content
            wordpress_id
            wordpress_parent
            menu_order
            acf {
              subtitle
              headlines
              graphes {
                title
                subtitle
                picture {
                  source_url
                  title
                }
                content
              }
              blocks_page {
                __typename
                ... on WordPressAcf_paragraph {
                  paragraph
                }
                ... on WordPressAcf_video {
                  video
                }
                ... on WordPressAcf_gallery {
                  gallery {
                    source_url
                    title
                  }
                }
                ... on WordPressAcf_image {
                  image {
                    source_url
                    title
                  }
                }
              }
              organigramme_page {
                __typename
                ... on WordPressAcf_one_item {
                  title
                  subtitle
                  content
                }
                ... on WordPressAcf_three_items {
                  column_1 {
                    items {
                      title
                      subtitle
                      content
                    }
                  }
                  column_2 {
                    items {
                      title
                      subtitle
                      content
                    }
                  }
                  column_3 {
                    items {
                      title
                      subtitle
                      content
                    }
                  }
                }
                ... on WordPressAcf_three_items_dash {
                  column_1 {
                    items {
                      title
                      subtitle
                      content
                    }
                  }
                  column_2 {
                    items {
                      title
                      subtitle
                      content
                    }
                  }
                  column_3 {
                    items {
                      title
                      subtitle
                      content
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWordpressPage.nodes
}
