import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

// import SVG icons for the homepage
import PrestationsSvg from '../components/svg/PrestationsSvg'
import EditoSvg from '../components/svg/EditoSvg'
import ProjetSvg from '../components/svg/ProjetSvg'
import CompteNewSvg from '../components/svg/CompteNewSvg'
import CalendarSvg from '../components/svg/CalendarSvg'
import RessourceSvg from '../components/svg/RessourceSvg'
import HandsSvg from '../components/svg/HandsSvg'
import GouvernailSvg from '../components/svg/GouvernailSvg'

import { ImageResizer } from '../utils/imageResizer'

export default ({ data }) => {
  const allWordpressId = [
    { id: 9 },
    { id: 9, svg: <CalendarSvg /> }, //
    { id: 15 },
    { id: 15, svg: <CompteNewSvg /> }, //  <PrestationsSvg />
    { id: 7, svg: <ProjetSvg /> },
    { id: 7 },
    { id: 13, svg: <HandsSvg /> },
    { id: 13 },
    { id: 11 },
    { id: 11, svg: <GouvernailSvg /> },
    { id: 17 },
    { id: 17, svg: <EditoSvg /> },
    { id: 19, svg: <RessourceSvg /> }, //
    { id: 19 },
    { id: 21, svg: <PrestationsSvg /> },
    { id: 21 },
  ]

  const wordpressPages = allWordpressId.map((item, index) => {
    let page = data.allWordpressPage.edges.find(
      page => page.node.wordpress_id == item.id
    )
    return {
      node: page.node,
      svg: item.svg,
    }
  })

  return (
    <Layout slug={''}>
      <div className="grid-wrapper">
        {wordpressPages.map(({ node, svg }, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundImage:
                  svg == undefined && node.featured_media !== null
                    ? `url(${ImageResizer(
                        node.featured_media.source_url,
                        1000,
                        1000,
                        false
                      )})`
                    : '',
              }}
              className={`grid-item ${svg !== undefined ? 'icon-svg' : ''}`}
            >
              <Link to={`/${node.slug}`} key={index}>
                {svg !== undefined ? (
                  svg
                ) : (
                  <div className="inner">
                    <h2>{node.title}</h2>
                  </div>
                )}
              </Link>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWordpressPage(
      filter: { wordpress_parent: { eq: 0 } }
      sort: { fields: [wordpress_id] }
      limit: 8
    ) {
      totalCount
      edges {
        node {
          type
          title
          slug
          path
          featured_media {
            id
            source_url
          }
          content
          wordpress_id
          wordpress_parent
          menu_order
        }
      }
    }
  }
`
