import React from 'react'

const CalendarSvg = () => (
  
  <svg stroke-width="0" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 264.9 248.2">
    <path fill="#fff" d="M207.3 119.3c.2-24.3 0-57.4-1.5-57.4h-.3l.3-.2c3-2.2 2-8.3 2.1-11 .3-5.4.3-10.8 0-16.1-.3-4.6-.3-5.8-6.8-6-39.6-.8-80-.5-119.7.6-7.5.2-7.1 1.8-7.1 6.8v19.9c0 4.1-1.4 6.9 4.9 7.5l4.1.3c2.3 2.9-2 63.2-3 69-3.7 22.8-11.3 46-26.6 63.8a73.3 73.3 0 0 0 24.5 10.2v10.4c0 .6 0 1.2.4 1.7s1.1.5 1.8.5l30.5.5c35.1.4 72.1-.4 102-18.6-2.7-27.2-4.7-54.6-5.9-81.9h.3Z" />
    <path d="m212.5 137.4-.9-18.1V84.1c0-4.7-.2-9.4-.4-14.1-.1-2.4-.1-5.1-.6-7.5 1.7-3 1.8-6.8 1.7-10.4l.2-7.8c0-3.3 0-6.5-.2-9.8-.1-2-.2-4.2-1.1-6-1-1.9-2.6-3-4.6-3.6-2.5-.7-5.2-.6-7.8-.7L189 24c-25.9-.2-51.8 0-77.6.3l-19.4.4-9.6.2a19 19 0 0 0-7.1.9c-2.3.8-4 2.4-4.8 4.7-.5 1.5-.5 3.2-.5 4.8v19.2c0 4.2-.9 8.8 3.2 11.6 1.9 1.3 4.2 1.6 6.5 1.8v8.9c-.2 8.6-.7 17.3-1.2 25.9-.5 7.8-1 15.7-1.7 23.5l-.3 3.7c0 .5 0 1-.2 1.4-.7 2.8-1 5.8-1.6 8.6-.8 3.6-1.6 7.2-2.6 10.8-1 3.5-2 7-3.3 10.4l-2 5.3-.5 1.2c-.2.5 0 0 0 0l-.2.6-1.2 2.7c-3.1 6.6-6.8 13-11.1 18.9l-.4.5-.8 1-1.9 2.3a4.4 4.4 0 0 0 0 6.1l.8.6a73.5 73.5 0 0 0 22.4 9.8v5.1c0 1.4 0 3 .3 4.4a5.8 5.8 0 0 0 4.1 3.9c1.4.3 2.9.3 4.4.3h4.2l8.5.2c10.4.2 20.9.4 31.3.3 19.5-.2 39.2-1.4 58.1-6.5 10.6-2.8 20.8-7.2 30.3-12.8 1.2-.7 2.3-2.2 2.1-3.7-2.1-21.2-3.8-42.5-5-63.8h.2Zm-9-80.3v-.5.5ZM78.8 32.8v.5-.5Zm-.8 26c-.2 0-.3-.2 0 0Zm.6-.4V33.7h.9l4.1-.2 18.2-.4c24.4-.5 48.8-.7 73.3-.5l18.2.2c2.9 0 5.7 0 8.6.2h1.5v.7c.4 5.7.5 11.4.1 17.1V57l-.2.5h-.8l-12.1.2-40.6.5-43.1.6c-7.7 0-15.4 0-23.1.3h-4.9v-.7Zm4.2 158.9c0 .2 0 .4 0 0Zm116.1-13.5-.8.3-.5.2-.4.2-.8.3-3.3 1.3-6.3 2.1c-6.6 2-13.3 3.5-20.1 4.5l-3.1.5h-.8l-1 .1h-1.1c-18.8 2-37.7 2.6-56.6 1.9l-18-.3h-3.4v-8.4c0-1.8-1.3-3.7-3.2-4.1-4.3-1.1-8.6-2.4-12.7-4l-.6-.2-.4-.2-1.2-.5-3.1-1.5-1.3-.7c8.4-11 14.4-23.6 18.6-36.8 3.1-9.9 5.5-20.1 6.4-30.4.7-7.7 1.2-15.5 1.7-23.2.6-9 1.1-18 1.3-27.1v-9.6h2.1c6.3 0 12.5-.2 18.8 0h19.4c13.1 0 26.2 0 39.3-.2 6.4 0 12.8-.2 19.1-.3 4.5 0 9 .5 13.4 0l1.8-.2.4 9.7c.3 11.3.3 22.6.2 33.9v8.5c1 21.2 2.4 42.5 4.2 63.7l1.5 15.8a89.6 89.6 0 0 1-9.8 4.9h.3Z"/>
    <path fill="#86c2eb" d="M83.3 63.6c2.7 0-1.8 63.1-2.8 69.1-3.7 22.8-11.4 46-26.6 63.8 16.9 11.5 40.8 13.7 60.8 14.9a219 219 0 0 0 59.9-4.4c4.5-.9 11-1.1 14.3-4.7 14-15.4 16.9-38.5 17.9-58.4.3-6.9 1.2-82.1-1.1-82L83.3 63.6Z" />
    <path d="M188 196.9c-.2.2 0 .1 0 0Z"/>
    <path d="M211.3 76.1c-.1-4.5-.2-9-.8-13.4-.2-1.7-.8-3.6-2.5-4.5-1-.5-1.9-.6-3-.6l-21.3.3-38.4.5-38.7.5-22.2.3h-1c-1.1 0-2.2.5-3 1.3s-1.3 1.8-1.3 3 .2 1.1.4 1.7l.2.3v11.1c-.4 16.5-1.4 33-2.9 49.4l-.3 3.7c0 .5 0 1-.2 1.4-.7 2.8-1 5.8-1.6 8.6-.8 3.6-1.6 7.2-2.6 10.8-1 3.5-2 7-3.3 10.4l-2 5.3-.5 1.2c-.2.5 0 0 0 0l-.2.6-1.2 2.7c-3.1 6.6-6.8 13-11.1 18.9l-.4.5-.8 1-1.9 2.3a4.4 4.4 0 0 0 0 6.1l.8.6c9.5 6 20 10 31.1 11.9 11.1 2.2 22.4 3.1 33.6 3.7 13.5.7 27.1.3 40.5-1.5 6.7-.9 13.2-2.2 19.9-3.3 5.2-.9 10.7-1.4 14.8-5.1 2-1.8 3.6-4.2 5.2-6.4 4.6-6.7 8-14.3 9.9-22.2 2.8-10.5 3.9-21.4 4.5-32.2.5-9.4.4-18.8.5-28.2 0-13.6.1-27.2-.2-40.7Zm-9.2-11.5v-.4.3Zm.9 41c0 11.5 0 23-.3 34.6-.1 6-.5 12-1.2 17.9l-.2 1.7v1l-.1.1-.7 4.2c-.9 5.1-2.2 10.1-3.8 15l-1.3 3.6-.4.9v.2l-.9 2.1a53.5 53.5 0 0 1-5.9 9.7l-.2.2v-.2l-.6.9-1.2 1.5c-.2.2-1.1 1-.8.9l-1.2.7-.4.2-.4.2-1.9.5c-2.3.6-4.7.8-7 1.2l-9.1 1.7-4.8.8-2.4.4-2 .3a220 220 0 0 1-19.8 1.6c-12 .4-24-.2-36-1.4l-4-.4-2-.2h-.6l-1.1-.2c-8.1-1-16-2.9-23.7-5.7l-1.7-.6-1-.4h-.2l-3.5-1.6-2.4-1.3c8.4-11 14.4-23.6 18.6-36.8 3.1-9.9 5.5-20.1 6.4-30.4.7-7.7 1.2-15.5 1.7-23.2.6-9 1.1-18 1.3-27.1 0-3.2.2-6.5 0-9.7v-.3h10.3l35.2-.6 39.5-.5 27.8-.4h1.1l.2 2.4c.7 12.3.6 24.6.6 36.9v-.4Zm-1.8 55.2c0 .1 0 0 0 0Z"/>
    <path id="letter1" className="letter" d="M109.8 141.1h-4.3c-.5 0-.9-.3-1.4-.4l-1-.3 4.6-6.9a58 58 0 0 0 6-10.9c1.5-3.8 2.2-8.2 1.3-12.3-.9-3.8-3.7-7.6-7.7-8.4-5.3-1-10.6 2.5-12.8 7.2-1 2-.6 4.7 1.5 5.9 1.9 1 4.8.6 5.9-1.5l.7-1.3.5-.5.5-.5.9-.6.8-.2.3.3.2.4c0 .2.2.3.3.5l.3.9c0 .2 0 1 .2.9v3.1c0 .5-.3 1-.4 1.4l-.4 1.4-.4 1.1-1.2 2.5c-1 1.9-2.2 3.7-3.4 5.5l-8 12c-1.2 1.8-.5 4.9 1.5 5.9a32 32 0 0 0 15.5 3.4c2.2 0 4.4-1.9 4.3-4.3 0-2.3-1.9-4.4-4.3-4.3Z"/>
    <path id="letter2" className="letter" d="m130.4 135.3.3-.2-.3.2Zm6.3-30.6c-8-3.8-15.3 3.9-18 10.8-1.3 3.5-1.4 7.3-1.5 11 0 3.7 0 7.5 1.7 10.9 1.4 3.1 4.1 5.7 7.4 6.6 3.7 1 7.4-.2 10.3-2.6 5.6-4.6 6.6-12.5 7.4-19.3.8-6.5-.6-14.3-7.2-17.4h-.1Zm-1.3 12.1c0-.2 0-.3 0 0Zm-.5-2c-.2-.4 0-.2 0 0Zm-2.1-2.5c.3 0 .5.3 0 0Zm-4.2 2.8c0 .2-.4.5 0 0Zm-1.5 2.5Zm-.7 15.9c0 .2.2.7 0 0Zm2.3 2.3c.6 0 .3 0 0 0Zm6.7-14.6-.4 3.2-.8 4.3-.5 1.8-.4 1.2-.7 1.3-.4.6-.2.2-1.2 1.2-.3.2-.3.2-.3.2h-1.1l-.5-.2-.6-.4-.5-.5-.7-1.2-.4-1.3v-.7l-.2-.9v-4.6c0-3.2 0-5.1 1.1-8v-.3l.4-.7.8-1.4.4-.6 1.3-1.4.3-.3.2-.2 1.3-.8.7-.2c.2 0 .3.2.5.3l-.2-.2.2.2.2.2.5.5.7 1.3v.2l.2.7.2 1.1v4.4l.4.6Z"/>
    <path d="M133.5 112.8h.2Zm-.9-.5c-.4 0 .7.2 0 0Zm-2.2 1-.2.2.2-.2Z"/>
    <path id="letter3" className="letter" d="M162.8 146.8c-2.4 0-3.6-.2-5.8-.6h-.6c3.4-4.3 6.3-8.9 8.5-13.8 1.7-4.1 2.7-8.3 1.8-12.7-.8-3.5-3.1-7.1-6.5-8.7-4.1-1.9-9.3-1.5-12.4 2-1.5 1.7-1.8 4.4 0 6.1 1.6 1.5 4.5 1.8 6 0l.4-.4c.3 0 1.1-.3.2 0H156.1l.4.2h.2l.5.6.5.9v.3l.3 1.2v2.8l-.8 2.8-.9 2.1-1.4 2.8c-1 1.7-2 3.4-3.2 5-.9 1.3-2 2.6-3.3 4.1-1.5 1.7-3.1 3.4-3.4 5.7-.4 2.5.6 4.4 3 5.3 4.6 1.7 9.5 2.6 14.3 2.7 2.2 0 4.4-2 4.3-4.3 0-2.4-1.9-4.2-4.3-4.3l.4.3Z"/>
    <path id="letter4" className="letter" d="M191.2 121a13 13 0 0 0-5.9-2.8l.7-1.1c1.7-2.7 4.4-5.7 3.5-9.2-.8-3.1-3.6-4.7-6.7-5.2-3.2-.5-6.3-.6-9.5-.2-2.2.3-4.4 1.8-4.3 4.3 0 2.1 1.9 4.6 4.3 4.3 2.1-.3 4.2-.3 6.3-.2 0 .2-.2.4-.4.6l-1.8 2.9-3.7 5.7c-1.6 2.5.5 6.8 3.7 6.5l3.3-.3h2.3l1.2.3h.3l.8.6.4.3.5.5.4.6c0 .2.1.3.2.3v.2l.2.9v.9c0 .2-.2 1.2 0 .4l-.3 1.1-.2.6-.5 1-.5.7-1.1 1.1-.5.4-.7.4-.8.3-1 .2c1-.2 0 0-.2 0h-1.4c-.4 0-.8-.2-1.1-.3h-.2l-.9-.5-.4-.3-.9-.9c-1.6-1.6-4.5-1.7-6.1 0a4.5 4.5 0 0 0 0 6.1 14.7 14.7 0 0 0 20.5.9c6-5.2 7.2-15.8.7-21.1h-.2Z"/>
  </svg>
  
)

export default CalendarSvg
