import React from 'react'

const PrestationsSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
    <g id="fond">
      <path
        fill="#fff"
        d="M274.8 141.8c.3-25-9-48.8-25.5-67.5-16.5-18.7-40.8-31.4-65.6-34.3-9.4-1.1-29.6-1.6-42.5.2C119.5 43 98.7 52.3 82 66.3s-28.7 32.1-35.3 53c-7.8 30.3-3.8 61.8 13.6 87.8s43.5 45.8 73.8 53.5l.1.3c16.9 4.6 34.6 5.8 51.9 3.7 6.3-.8 12.5-2 18.6-4 23.2-7.6 41.6-26.5 52.6-48.3 11.1-21.6 16.8-46.1 17.5-70.5"
      />
      <path d="M232.2 53.7c-27-18.3-61.1-22.2-92.9-17.6-34.8 5-66.7 25.9-84.9 56C35.9 122.5 33.7 161 48 193.6c15.1 34.3 47.8 61 83.8 70.9.4.3.9.5 1.4.7 28.6 7.5 62.8 7.9 89-7.6 24-14.2 39.3-38.6 48.1-64.5 5.6-16.5 8.4-33.8 8.9-51.2-.1-35.3-17.9-68.5-47-88.2zm2.6 183c-11.6 11.7-25.9 19.6-42.2 22.7-18.5 3.5-37.8 2.3-56-2.3-.4-.3-.8-.5-1.3-.6-30-7.8-56.1-27.5-72.7-53.7-17.8-28.2-20.8-65.8-6.4-96.1C70.1 77.2 96.9 55.4 128.3 47c14.6-3.9 29.8-4.2 44.8-3.6 14.7.6 28.9 3.8 42.1 10.3 33.4 16.5 55.3 50.5 55.2 88-1 33.9-11.2 70.4-35.6 95z" />
      <path d="M180.5 103.2c-.6 3.7-2.1 7-3.5 10.5-2-5.5-4.6-10.8-8.6-14.8-2.1-2.1-6.6-1.4-7.2 1.9-1.2 7-3 13.9-5.2 20.7-1.7 5.3 6.5 7.5 8.3 2.3 1.2-3.6 2.2-7.3 3.1-11 2.2 4.9 3.5 10.4 4.9 15.1 1 3.4 6.1 4.4 7.8 1 1.8-3.4 3-7 4.2-10.6 1.5-4.3 3.6-8.2 4.3-12.7 1-5.5-7.2-7.8-8.1-2.4z" />
      <path d="M93.7 127.3c-11.1-2.3-22.3-4-33.5-5.2-5.5-.6-5.4 8 0 8.6 10.5 1.1 20.9 2.8 31.2 4.9 5.4 1.1 7.7-7.2 2.3-8.3z" />
      <path d="M224.7 171.5c10.4 2.4 20.9 4.7 31.3 7.1 5.4 1.2 7.7-7.1 2.3-8.3-10.4-2.4-20.9-4.7-31.3-7.1-5.4-1.1-7.7 7.1-2.3 8.3z" />
      <path d="M183.3 56.8c-2.7 11.7-5.6 23.4-8.5 35.1-1.4 5.4 6.9 7.6 8.3 2.3 3-11.7 5.8-23.4 8.5-35.1 1.3-5.4-7-7.7-8.3-2.3z" />
      <path d="M145.3 211c-3.1 10.5-6 21.1-8.6 31.8-1.3 5.4 6.9 7.6 8.3 2.3 2.7-10.6 5.5-21.2 8.6-31.8 1.5-5.3-6.8-7.6-8.3-2.3z" />
    </g>
    <g id="compass">
      <path
        d="M243.5 128.7c-24.9 6.5-47.4 13.4-65.3 16.9-5.3-3-11.9-5.4-18-4.7-4.6.6-9.3 2.9-12 6.7-2.7 3.8-5.1 7.6-5.8 12.5-17.6 11.9-37.9 24.1-59.3 35.3 24.6-7.5 47.7-12.5 65.5-16.8 5.4 2.9 12.1 5.1 18.1 4.2 4.6-.7 8.7-3.6 11.3-7.5 2.6-3.9 4.8-8.5 4.9-13.1 18-11.1 38.8-23.1 60.6-33.5z"
        fill="#75B4E6"
      />
      <path d="M246 132.2c2.1-1.2 2.2-3.4 1.3-5.1-.7-1.8-2.5-3.1-4.9-2.5-21.1 5.6-42.1 12.1-63.5 16.6-8-4.1-17.2-6.3-25.7-2.6-7.5 3.3-12.6 11.2-14.5 19-18.6 12.5-37.9 23.8-57.6 34.2-4.3 2.3-1.3 9.2 3.3 7.8 21-6.4 42.4-11.2 63.8-16.4 7.9 3.8 17.2 6.1 25.4 1.9 7.4-3.8 12.1-12.2 13.4-20.3 18.9-11.7 38.2-22.6 58.2-32.2.2 0 .6-.2.8-.4zm-58.1 21.9c-2.5 1.5-6.2 3-8.1 5.1-1.5 1.6-1.1 3.3-1.5 5.4-.4 2-1.2 3.8-2.2 5.6-1.7 3.1-3.8 6-7.1 7.6-3.6 1.6-7.6 1.2-11.3.1-3.2-.9-6.3-3.4-9.7-3.4-2.8 0-6.3 1.5-9.1 2.1-3.3.8-6.7 1.6-10 2.4-4.4 1-8.8 2.1-13.2 3.2 4.2-2.5 8.4-5 12.5-7.6 3.5-2.2 7.1-4.5 10.6-6.8 2.3-1.5 5.9-3 7.3-5.4 1.1-1.7 1.1-4.2 1.9-6 .6-1.4 1.4-2.7 2.2-4 2-3.1 4.1-5.3 7.7-6.5 3.7-1.2 7.6-.8 11.3.4 3.2 1 6.2 3.5 9.6 3.6 2.9 0 6.4-1.3 9.1-1.9 3.3-.8 6.7-1.6 10-2.5 2.9-.8 5.8-1.5 8.8-2.3-2.7 1.5-5.4 3.1-8.1 4.7-3.6 2-7.2 4.1-10.7 6.2z" />
      <path
        fill="#fff"
        d="M157 154.9c2.1-1.5 5.7-2.8 8.6-1.5 2.9 1.2 5.1 4.2 5.3 7.4 0 .9-.1 1.8-.3 2.6-.7 2.7-2.6 5.1-5.1 6.2s-5.6 1-7.9-.6-4.2-4.7-4.2-7.4c-.1-1.3 1.3-5.1 3.6-6.7z"
      />
      <path d="M173 154.1c-4.2-6.3-12.2-6.9-18.2-2.9-.2.1-.4.3-.6.4-.1.1-.2.1-.3.2-4.9 4.2-6.3 11-2.8 16.4 3.8 6 11.8 8.2 17.9 4.2 6-3.9 8.1-12.3 4-18.3zm-9.2 11.4c-2.5 1.2-5.1-.4-5.9-2.9-.5-1.6.4-3.1 1.7-4.4 2-1.2 4.4-1.9 6.1.2 1.8 2.4.7 6-1.9 7.1z" />
    </g>
  </svg>
)

export default PrestationsSvg
