import React from 'react'

export default ({ clickEvent }) => (
  <div
    className="hamburger"
    onClick={e => (clickEvent ? clickEvent(e) : e.preventDefault())}
  >
    <span />
    <span />
    <span />
  </div>
)
