import React from 'react'

const EditoSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
    <g id="fond">
      <path
        d="M232.8 212.9c-6.7-11.3-13.6-23.6-19.3-35.4-14.5 15-34 26.7-53.9 32.6 7.8 10.7 16 22.3 23.4 33.4 1.4 2.1 3.4 3.6 5.8 4.3 1.2.4 2.5.7 3.4.9 6.5 1.6 13.2 4.2 19.1 2.9 7.9-1.6 15.5-6.2 18.8-13.6.8-1.9 2.3-7.2 2.8-9.2.5-1.8 1.2-6.6 1.3-10.3.2-1.9-.4-3.8-1.4-5.6z"
        fill="#75B4E6"
      />
      <path d="M159.4 104c-3.7-3.3-8.1-5.2-12.2-7.8-3.5-2.2-6.8 1.4-6.3 4.8.3 1.6.4 3.2.6 4.8-2.3-1-4.7-1.8-7.1-2.7-3.4-1.2-5.8 2.3-5.3 5.3.3 1.6.4 3.1.5 4.7-2.2-1.2-4.4-2.3-6.7-3.1-3.4-1.2-5.8 2.3-5.3 5.3.6 4 1.8 7.9 2.4 11.8.9 5.4 9.2 3.1 8.3-2.3-.2-1-.3-2-.5-2.9 1.3.8 2.6 1.7 4 2.5 3 1.8 6.2-.6 6.5-3.7.2-2.3.2-4.6.1-6.9 2 .9 3.9 1.9 5.7 3.2 2.7 2 6.5-.7 6.5-3.7 0-1.9-.1-3.7-.2-5.6 1.1.7 2.2 1.4 3.2 2.3 3.9 3.8 9.9-2.3 5.8-6z" />
      <path d="M237 211.8c-.1-.3-.2-.7-.5-1-.1-.2-.2-.4-.3-.5-6.7-11.4-13.2-22.9-19-34.9-.1-.1-.1-.3-.2-.4l-.3-.9c-5.4-10.4-11-21.7-11-33.7 0-11.8 2.4-23.4 1.3-35.2-2.1-22.2-11.1-41.4-27.3-56.8-.2-.2-.4-.3-.6-.5l-.2-.2c-12-11.2-28.4-13.6-44.3-12.3-.3 0-.6.1-.8.2-16.7 1.9-32.8 7.7-45.8 18.6-12.6 10.5-23.1 25.1-27.7 41-4.9 16.8 1.2 34.9 10.2 49.3 8.8 14.1 22.9 25.4 37.8 32.4 8.8 4.1 18.8 6.6 26.8 12.2 8.4 5.9 14.6 14.6 20.5 22.8.1.2.2.3.3.5 8.1 11.2 15.5 23.1 23.9 34 .9 1.2 1.9 2.1 2.9 2.9.5.7 1.2 1.2 2.1 1.3 3 1.6 6.4 2.3 10 3.3 5.8 1.6 11.7 3.2 17.8 2 9.5-1.8 18.6-8.1 22.1-17.3 3-8 5.7-18.7 2.3-26.8zm-50.2 29.3c-1-1-1.9-2.2-2.7-3.5 14.9-8 28.8-17.3 41.8-28 .9 1.6 1.9 3.2 2.8 4.8-12.4 10.9-26.8 20.1-41.9 26.7zm-10.9-15.6c15.2-8.2 29.4-18.2 42.3-29.7 1.1 2.1 2.3 4.2 3.4 6.3-13.1 10.9-27.3 20.3-42.3 28.3-1.1-1.6-2.3-3.3-3.4-4.9zm-9.4-13.1c16.9-6 32.6-15.4 45.8-27.7.6 1.1 1.1 2.2 1.7 3.3-13.1 11.9-27.6 22-43.1 30.4-1.5-2-2.9-4-4.4-6zm-34.8-35c-15.5-6.7-30.3-12.4-42.9-24.2-11.2-10.6-20.3-26.5-21.7-42.1-1.4-16.1 8.8-32.6 19.5-43.8C99.5 53.7 116.4 46 135 44.1c.1 0 .2 0 .3-.1 13.3-1 27.4.4 37.5 9.8.2.2.4.3.6.5.1.1.1.2.2.2 16.7 15.9 25.4 36.7 25.1 59.8-.1 10.8-2.7 21.8-1.2 32.6 1.5 10.6 6 20.4 10.8 29.8-3.2 3.1-6.6 6.1-10.2 8.8-11.7-21.1-23.5-42.3-35.2-63.4-2.7-4.8-10.1-.5-7.4 4.3 11.9 21.4 23.7 42.8 35.6 64.1-2.4 1.6-4.9 3.1-7.4 4.6-.1-.2-.2-.4-.4-.6-13.9-18.3-27.3-36.8-40.4-55.6-3.1-4.5-10.6-.2-7.4 4.3 13.1 18.8 26.6 37.4 40.4 55.6.1.1.1.2.2.3-4.8 2.3-9.8 4.3-14.8 6-8-11.1-16.8-22.2-29.6-27.7zm94.9 58.4c-3 7.5-12.2 12.5-20.1 11.9-3.1-.2-6.4-1-9.5-1.9 11.7-5.8 22.8-12.8 32.9-21.1-.8 4-2.2 8.2-3.3 11.1z" />
    </g>
    <g id="blink">
      <path d="M114.3 79.8c1 1.6 2 3.3 3 4.9 1.2 1.9 3.9 2.8 5.9 1.5 1.9-1.2 2.8-3.8 1.5-5.9-1-1.6-2-3.3-3-4.9-1.2-1.9-3.9-2.8-5.9-1.5-1.9 1.3-2.8 3.9-1.5 5.9z" />
      <path d="M131.6 71.8c.3 1.9.5 3.8.8 5.7.3 2.2 3.3 3.6 5.3 3 2.4-.8 3.3-2.9 3-5.3-.3-1.9-.5-3.8-.8-5.7-.3-2.2-3.3-3.6-5.3-3-2.5.8-3.4 2.9-3 5.3z" />
      <path d="M100.5 94.6c1.6 1.1 3.2 2.2 4.7 3.3 1.8 1.3 4.8.4 5.9-1.5 1.2-2.2.4-4.5-1.5-5.9-1.6-1.1-3.2-2.2-4.7-3.3-1.8-1.3-4.8-.4-5.9 1.5-1.2 2.2-.5 4.5 1.5 5.9z" />
      <path d="M151.2 69.6c-.7 1.8-1.5 3.6-2.2 5.3-.9 2.1.9 4.8 3 5.3 2.5.6 4.4-.8 5.3-3 .7-1.8 1.5-3.6 2.2-5.3.9-2.1-.9-4.8-3-5.3-2.5-.6-4.4.7-5.3 3z" />
      <path d="M94.9 112.1c1.9.2 3.8.3 5.8.5 2.2.2 4.4-2.1 4.3-4.3-.1-2.5-1.9-4.1-4.3-4.3-1.9-.2-3.8-.3-5.8-.5-2.2-.2-4.4 2.1-4.3 4.3.1 2.5 1.9 4.1 4.3 4.3z" />
    </g>
  </svg>
)

export default EditoSvg
