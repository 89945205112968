import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

const Navbar = ({ toggleMenu }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main_nav" } }) {
          edges {
            node {
              name
              count
              items {
                title
                url
                type
                object_slug
                wordpress_children {
                  title
                  url
                  type
                  object_slug
                }
              }
            }
          }
        }
      }
    `}
    render={({ allWordpressWpApiMenusMenusItems }) => (
      <nav className="navbar">
        <div className="scroll-container">
          <ul className="main-nav">
            {allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
              (item, index) => (
                <li key={index}>
                  {item.type === 'custom' && (
                    <a href={item.url} target="_blank">
                      {item.title}
                    </a>
                  )}
                  {item.type === 'post_type' && (
                    <Link
                      to={`/${item.object_slug}`}
                      onClick={e => toggleMenu(e)}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    ></Link>
                  )}

                  {item.wordpress_children !== null && (
                    <ul className="sub-nav">
                      {item.wordpress_children.map((child, index) => (
                        <li key={index}>
                          {child.type === 'custom' && (
                            <a href={child.url} target="_blank">
                              {child.title}
                            </a>
                          )}
                          {child.type === 'post_type' && (
                            <Link
                              to={`/${item.object_slug}#${child.object_slug}`}
                              onClick={e => toggleMenu(e)}
                              dangerouslySetInnerHTML={{ __html: child.title }}
                            ></Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </nav>
    )}
  />
)

export default Navbar
