import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

function pageTrigger() {
  let body = document.body
  body.classList.add('full_page_open')
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(slug: { eq: "menu-secondaire" }) {
          items {
            title
            url
            type
            object_slug
          }
        }
      }
    `}
    render={({ wordpressWpApiMenusMenusItems }) => (
      <nav onClick={pageTrigger} className="footer-nav">
        <ul>
          {wordpressWpApiMenusMenusItems.items.map((item, index) => (
            <li key={index}>
              {item.type === 'custom' && (
                <a href={item.url} target="_blank">
                  {item.title}
                </a>
              )}
              {item.type === 'post_type' && (
                <Link to={`/${item.object_slug}`}>{item.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    )}
  />
)
