import React from 'react'

const ProjetSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 320 320"
  >
    <g id="eye">
      <defs>
        <path
          id="SVGID_1_"
          d="M237.3 174c-13.5 7.1-29 9.8-44.1 11.4-1.4-.9-3.3-.6-4.6.5-.3 0-.6.1-.9.1-19.5 1.8-39.3 2.3-58.7-.5-13.5-2-26.7-4.1-39.4-9.3-16.1-6.6-29.8-16.8-40.9-29.9C61.3 111.6 94.9 87 129 75.6c20.1-6.8 40.6-7.9 61.1-2.2 18.7 5.2 36.8 14.5 52 26.6 14.5 11.7 22.4 26.6 29 43.6-9.3 12.1-20.2 23.2-33.8 30.4z"
        />
      </defs>
      {/* <clipPath id="SVGID_2_">
        <use xlink:href="#SVGID_1_" overflow="visible" />
      </clipPath> */}
      <g id="eye-pupil" clipPath="url(#SVGID_2_)">
        <ellipse cx="163.5" cy="128.5" rx="63.3" ry="61.2" fill="#75B4E6" />
        <path
          fill="#222221"
          d="M186.5 66.8c-26.3-9.3-57.2-1.3-75.2 20.1-17.7 21.1-20.4 51.8-5.7 75.4 14.9 23.8 43.6 35.5 71 30.5 30.9-5.7 54.2-32.8 54.6-64.3-.4-28-18.5-52.4-44.7-61.7zm-3 115.3c-23.1 7.9-49.3 1.3-65.4-17.1-16-18.3-18-45.4-5.2-66 12.9-20.6 38.3-31 62-26.4 26.9 5.2 47.3 28.3 47.6 55.9-.3 24.1-16.1 45.7-39 53.6z"
        />
        <path
          fill="#222221"
          d="M191.1 121c0-.3-.1-.6-.2-.9-.1-.3-.2-.6-.4-.9-4.3-11.4-15.4-19.2-27.8-18.7-12.8.5-22.9 9.9-26.1 22.1-2.9 11 .8 22.7 10.1 29.4 9.3 6.6 21.8 6.9 31.4.8 10.9-6.8 16-19.4 13-31.8z"
        />
        <path
          id="eye-pupil-white"
          d="M189.1 105.8c0-.3-.1-.6-.2-.9-.1-.6-.4-1-.7-1.5-2.4-4.4-7.4-6.8-12.3-4.9-5 1.9-7.7 8-5.8 13 1.9 5 7.6 7.5 12.6 5.6 4.6-1.8 7.2-6.5 6.4-11.3z"
          fill="#fff"
        />
      </g>
    </g>
    <path
      fill="#222221"
      d="M280.3 144.9c.3-1.1 0-2.1-.7-3-5.9-15.3-12.5-30.2-24.4-41.9-13.6-13.1-30.9-23-48.4-30-1.8-.7-3.6-1.4-5.5-2 5.8-11.5 11.7-23 17.5-34.4 2.5-4.9-4.9-9.3-7.4-4.3-6.1 11.9-12.1 23.9-18.2 35.8-.1.1-.1.2-.1.3-8.6-2.4-17.4-3.9-26.2-4.2-.5-13 0-26 1.3-38.9.6-5.5-8-5.5-8.6 0-1.3 13-1.7 25.9-1.3 38.9-4 .2-7.9.6-11.9 1.2-4.3.7-8.6 1.6-12.8 2.8-5.6-11.9-11.8-23.5-18.6-34.7-2.9-4.7-10.3-.4-7.4 4.3 6.5 10.7 12.4 21.7 17.8 32.9-12.9 4.4-25.2 10.7-36.4 18.2-15.1 10.1-29.3 22.3-39 37.7-2.2 3.5-4.2 7.2-5.9 11-1.5 3.4-5.4 9.7-4.4 13.5 1 3.7 5.8 7.6 8.4 10.2 3.5 3.6 7.2 6.9 11.2 10 17.7 13.8 39 21.1 61 24.7-3.4 6.7-6.8 13.5-10.1 20.2-2.5 4.9 4.9 9.3 7.4 4.3 3.9-7.8 7.8-15.5 11.7-23.3 9.5 1.1 19.2 1.5 28.6 1.5.5 9.8.7 19.6.6 29.4-.1 5.5 8.5 5.5 8.6 0 .2-9.9 0-19.7-.6-29.5 7.4-.2 14.9-.5 22.5-1.1 4.2 8.3 8.4 16.6 12.5 25 2.5 4.9 9.9.6 7.4-4.3l-10.8-21.6c12.5-1.5 24.7-4.2 36-8.9 18.6-7.7 32.8-21.7 44.7-37.5.8-.4 1.4-1.2 1.5-2.3zm-43 29.1c-13.5 7.1-29 9.8-44.1 11.4-1.4-.9-3.3-.6-4.6.5-.3 0-.6.1-.9.1-19.5 1.8-39.3 2.3-58.7-.5-13.5-2-26.7-4.1-39.4-9.3-16.1-6.6-29.8-16.8-40.9-29.9C61.3 111.6 94.9 87 129 75.6c20.1-6.8 40.6-7.9 61.1-2.2 18.7 5.2 36.8 14.5 52 26.6 14.5 11.7 22.4 26.6 29 43.6-9.3 12.1-20.2 23.2-33.8 30.4z"
      id="fond"
    />
  </svg>
)

export default ProjetSvg
