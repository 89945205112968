import React from 'react'

const HandsSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
    <g id="hands">
      <path
        fill="#75B4E6"
        d="M96.6 177.5l-7.3 9.9c-2.9 3.9-8.4 4.7-12.2 1.9-3.9-2.9-4.7-8.4-1.9-12.2l5.8-7.8"
      ></path>
      <path
        fill="#222221"
        d="M207.3 167.5l-6.2 6c2.2 1.9 4.3 3.1 5.4 6 2.9 7.5-6.7 6.5-10.7 3.8l-.6-.3c-5.5-4.1-11-8.3-16.6-12.4-4.4-3.3-8.7 4.1-4.3 7.4 5.3 4 10.7 8 16 12 .9 2.1.2 4.4-2.1 5.6-2.6 1.3-5.2.3-7.4-1.3-4.1-3.5-8.2-6.9-12.2-10.4-4.2-3.6-10.3 2.5-6.1 6.1 4 3.4 7.9 6.7 11.9 10.1 0 4.6-4.5 6.6-8.2 3-.2-.2-.4-.3-.6-.5-2.3-1.9-4.6-3.7-7-5.6-1.8-1.4-4.3-1.8-6.1 0-1.5 1.5-1.8 4.6 0 6.1 2.3 1.8 4.5 3.6 6.8 5.4.7 4.1-5.2.8-7.3-.7-2.5-1.6-4.7-3.6-7.1-5.3-2-1.4-4-2.7-6-4.1l-24.6-16.8c-5.2-3.5-10.3-7.2-15.5-10.6-8-5.1-17.4-7.2-26.4-9.8l-20.4-6c-3.7-1.1-8.5-1.8-12-3.3-.5-15.8-3.2-34.8 7.1-47.7 20.3 8.5 41.1 16.8 63.2 18.6 10.8.9 20.3-.9 30.8-3.1 2.2-.5 4.2-.3 6 .3l9.4-4.5c-7.4-6.5-15.6-4.3-24.2-2.4-28.7 6.6-59.5-7.4-85.3-18.2-1.3-.6-3.2.1-4.2 1.1-7.8 8-10.7 18-11.5 29-.5 6.8-.4 13.6-.1 20.4.2 4.6-1.1 10.7 3.3 13.4 2.3 1.5 5.3 1.9 7.9 2.6 3.7 1 7.5 2.1 11.2 3.2 8.1 2.3 16.2 4.7 24.3 7.2 2.8.9 5.7 1.7 8.5 2.7 3.2 1.1 4.3 1.6 8.1 3.9 2.6 1.7 5.1 3.5 7.7 5.2 10.9 7.4 21.8 14.9 32.8 22.3 4.8 3.3 9.4 7.1 14.3 10.1 4.4 2.7 9.7 4.6 14.4 1.6 1.8-1.1 3.1-2.8 3.8-4.6 3.2.8 6.6.6 9.5-1.1 3.1-1.8 4.7-4.7 5.5-7.8 7.5 1.3 15.5-3.5 16.9-10.3 6.3 1.1 12.9-.9 15.5-7.6 2.9-7.5-2.4-14.2-7.6-18.7z"
      ></path>
      <path
        fill="#75B4E6"
        d="M99.6 188c-4-2.6-5.2-8.1-2.5-12.1l3.2-4.9c2.6-4 8.1-5.2 12.1-2.5 4 2.6 5.2 8.1 2.5 12.1l-3.2 4.9c-2.6 4-8 5.1-12.1 2.5z"
      ></path>
      <path
        fill="#75B4E6"
        d="M114.4 197.9c-4-2.6-5.2-8.1-2.5-12.1l3.2-4.9c2.6-4 8.1-5.2 12.1-2.5 4 2.6 5.2 8.1 2.5 12.1l-3.2 4.9c-2.6 4-8.1 5.1-12.1 2.5z"
      ></path>
      <path
        fill="#75B4E6"
        d="M128.6 208.3c-4-2.6-5.2-8.1-2.5-12.1l3.2-4.9c2.6-4 8.1-5.2 12.1-2.5 4 2.6 5.2 8.1 2.5 12.1l-3.2 4.9c-2.6 4-8 5.1-12.1 2.5z"
      ></path>
      <path
        fill="#222221"
        d="M143.6 185c-2.9-1.8-5.8-2.3-8.6-1.8-.5-3.3-2.3-6.4-5.7-8.5-3-1.9-6.2-2.3-9.1-1.7-.6-3.2-2.3-6.1-5.6-8.2-9.2-5.8-19.1 2-22 11.1-2.5 3.7-6.8 12.2-11.8 10.6-7.5-2.5 1.6-11.9 3.9-15 3.3-4.5-4.2-8.7-7.4-4.3-2.1 2.9-4.7 5.7-6.4 8.8-3.5 6.4-1 14.7 5.6 18 7.6 3.8 13-.2 17.5-5.6.7 1 1.6 1.9 2.7 2.7.2.2.5.4.9.6.2.2.5.3.7.4 2.7 1.4 5.6 1.9 8.5 1.4.5 2.9 2 5.5 4.7 7.5.2.2.5.4.9.6.2.2.5.3.7.4 2.5 1.3 5.3 1.8 8 1.5.5 3 1.9 5.8 4.7 7.9.2.2.5.4.9.6.2.2.5.3.7.4 4 2 8.5 2.2 12.5 0 3.2-1.7 4.9-4.6 6.8-7.5 4-6.6 4.2-15.2-3.1-19.9zm-41.8-.7c-1.9-1.2-2.1-2.8-1.7-4.4.1-.1 1.8-3.7 2.2-4.3 1.7-2.7 4.3-6 7.8-3.4 2.9 2.1 1.7 5.1.2 7.5-.5.6-.9 1.3-1.3 1.9-1.6 2.5-3.7 4.7-7.2 2.7zm14.7 9.9c-3.6-2.4-1.1-6.2.6-8.7 1.7-2.7 4.3-6 7.8-3.4 2.7 2 1.8 4.7.4 7-.9 1.1-1.7 2.3-2.4 3.6-1.5 1.9-3.4 3.2-6.4 1.5zm22.5 6.3c-2 3-4.1 6.4-8.2 4-3.6-2.4-1.1-6.2.6-8.7 1.7-2.7 4.3-6 7.8-3.4 3.2 2.3 1.5 5.6-.2 8.1z"
      ></path>
      <path
        fill="#75B4E6"
        d="M291 151.5c-7.8-.6-16.3 1-23.9 2.6-3.4.7-6.8 1.6-10 3.1-5.9 2.8-14 7.2-19.5 10.6-9 5.4-18.2 6.6-28.6 4.6-2-.4-5.5-2-7.7-3.6-14.7-10-28.2-20.9-41.2-32.4-5.4-.3-10.6.9-15.4 3.5-4.3 2.4-8.1 5.8-12.8 7.4-2.5.9-5.3 1.1-8 .7-2.2-.3-4.4-1.1-6-2.6-1.6-1.5-2.3-3.4-2.1-5.4.3-2.7 2.5-4.6 4.5-6.3 2-1.7 4.7-3.5 7-4.8 11-6.1 26.5-11.5 38.3-10.9 12.8.7 26.3 4.1 39 2.9 29.7-3.4 57.9-16.9 85.4-30.1 3.4 3.8 5.4 8.7 6.4 13.7s.8 10.2.6 15.3c-.4 10.9-1.9 21.7-6 31.7z"
      ></path>
      <path
        fill="#222221"
        d="M293.3 87.9c-1.3-1.5-3.6-1.5-5.2-.7-31.4 15.1-65.9 33-101.8 28.9-8.4-1-17.1-2.8-25.6-2.2-7.8.6-15.4 2.7-22.6 5.5-9.1 3.6-32.3 12.9-25 26.4 3.9 7.2 14 7.9 20.9 5.4 4-1.5 7.3-4 10.9-6.3 4.3-2.7 8.8-4 13.7-4.2 9.6 8.4 19.5 16.5 29.8 24.1 5.1 3.8 10.3 8 16.2 10.5 4.9 2.1 10.8 2.5 16.1 2.2 12.1-.8 21.4-7.6 31.7-13.1 11.8-6.2 25.3-9.4 38.7-8.7 2.1.1 3.4-1.1 3.9-2.7.1-.2.2-.3.2-.5 4.3-11 5.9-22.9 6.4-34.7.4-10.7-1.1-21.4-8.3-29.9zM255 153.6c-9.9 4.5-19.2 12.4-30 14.8-5.2 1.1-11.7 1.2-16.7-.7-5.7-2.1-10.8-6.7-15.6-10.2-5.2-3.9-10.3-7.9-15.4-12-2.4-2-4.8-4-7.1-6s-5.1-5.4-7.9-6.8c-4.2-2.1-11.3 0-15.4 1.5-5.5 2.1-9.7 6.2-15 8.5-2.6 1.1-5.5 1.6-8.3.9-4.8-1.3-2.5-4.7 0-6.8 4.9-4 10.9-6.9 16.8-9.2 11.6-4.6 22.4-5.9 34.7-4.4 12.3 1.5 24 3 36.4 1.1 27.3-4.2 52.8-16.2 77.5-28.1 4.7 7.4 4.4 16.9 3.9 25.4-.6 8.7-1.9 17.2-4.7 25.4-11.1 0-23.4 2.1-33.2 6.6z"
      ></path>
    </g>
  </svg>
)
export default HandsSvg
