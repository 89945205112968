import React from 'react'

const CompteNewSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
    <g id="fond">
      <path d="M235.2 179.5c-4.8-9.6-12.5-17.1-20.3-24.3-5.4-4.9-10.8-9.9-15.2-13.9-9.8-8.8-18.9-18.5-28.1-27.9-8-8.2-15.9-16.6-24-24.7-6.7-6.6-13.8-12.9-21.3-18.6-8.7-6.7-19.1-13.4-30.5-13.2-10.6.2-21.4 7.5-26.4 16.8-4 7.4-3.4 15.7-.2 23.3 6.1 14.5 18.1 26.6 28.6 38 10.5 11.4 21.1 22.7 31.7 34 8.3 8.8 16.4 18.3 25.5 26.3.1.1.3.2.4.4 17.6 14.2 36.8 34.6 61.7 32.2 10-1 18.7-8.1 22.5-17.3 4.3-10.1.2-21.8-4.4-31.1zm-9 35.4c-9.3 8.3-23.5 3.8-33.3-1.3-11.8-6.1-21.2-15.7-31.4-23.9-6.7-5.8-12.6-12.6-18.6-19-9.3-9.8-18.6-19.7-27.8-29.6-6.1-6.5-12.3-13-18.2-19.7-6.6-7.5-13.5-15.5-18.3-24.4-3.6-6.8-5.2-14.5-.4-21.1 16.8-23.5 42.4-.2 56.9 13 6.9 6.2 13.2 12.9 19.7 19.6 16.5 17 33.1 33.7 50.5 49.6 8.5 7.7 17.4 15.3 22.5 25.8 5.2 10.4 8.1 22.3-1.6 31z" />
      <path d="M155.3 125.1c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M165.9 139.3c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M176.8 154.7c5.5 0 5.5-8.6 0-8.6s-5.6 8.6 0 8.6z" />
      <path d="M143.3 133.5c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M130.1 144c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M142 160.4c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M155.4 172.9c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M153.1 151.2c5.5 0 5.5-8.6 0-8.6-5.5.1-5.5 8.6 0 8.6z" />
      <path d="M165.5 164.5c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
    </g>
    <g id="bandage">
      <path
        d="M162.3 86.5c-12.9 15.9-24.5 32.1-38.6 47.2-14.8 15.9-31.1 32.8-36.7 54.2-2.5 9.4-5.1 16.4 2.2 25.9 17.2 22.4 46 1.5 59.2-9.7 5.3-4.5 8.2-9.3 12.7-14.3 14.4-16.2 28.3-32.8 42.9-48.8 15.7-17.3 43-58.9 7.7-72.8-10.2-4-22.1-2.3-31.7 3-7.2 3.7-12.8 9.2-17.7 15.3z"
        fill="#75B4E6"
      />
      <path d="M209.7 62.8c-19.7-5.4-38.3 5.9-50.4 20.6-12.6 15.6-24.4 31.8-37.9 46.5-11.7 12.7-24.1 25.4-32.2 40.8-7.1 13.7-13.7 32-3.3 45.5 8.9 11.6 22.9 13.9 36.2 9.1 12.3-4.5 25-13 33.8-22.8 13.5-15 26.3-30.7 39.7-45.9 9.2-10.5 18.8-20.5 26.2-32.4 6.1-9.9 11.7-21.6 11.5-33.5-.3-13.9-10.4-24.2-23.6-27.9zm4.6 57.2c-6 9.7-13.5 17.9-21 26.3-11.8 13.3-23.5 26.8-35.3 40.3-4.9 5.6-9.2 11.5-15 16.2-4.4 3.6-9 6.9-13.8 9.7-9.6 5.5-22.6 10.6-32.5 2.8-6.3-5-9.1-11.9-7.3-19.7 1.6-7.4 3.9-14.6 7.5-21.2 8.1-15.1 20.3-27.2 31.7-39.8 13.1-14.3 24.5-30 36.7-45.1 9.8-11.9 24.1-22.4 40.5-18.8 10.4 2.3 18.9 9.6 18.8 20.6 0 10.3-5.1 20.2-10.3 28.7z" />
      <path d="M133.2 154.6c5.5 0 5.5-8.6 0-8.6-5.6 0-5.6 8.6 0 8.6z" />
      <path d="M145 141.5c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M158 127.8c5.5 0 5.5-8.6 0-8.6-5.5.1-5.5 8.6 0 8.6z" />
      <path d="M143.7 164.8c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M156.6 175.7c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M170.4 160.8c5.5 0 5.5-8.6 0-8.6-5.5.1-5.6 8.6 0 8.6z" />
      <path d="M180 145.3c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M159.2 151.7c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M169.8 137c5.5 0 5.5-8.6 0-8.6s-5.5 8.6 0 8.6z" />
      <path d="M114.7 162.3c3.6 5.2 8.8 9.6 13.4 14 4.4 4.2 8.7 8.2 13.5 12 4.3 3.4 10.4-2.6 6.1-6.1-4.4-3.5-8.5-7-12.4-11-4.3-4.2-9.7-8.3-13.1-13.3-3.2-4.4-10.7-.1-7.5 4.4z" />
      <path d="M160.8 111.9c10.2 8.4 19.3 18 28.7 27.3 3.9 3.9 10-2.2 6.1-6.1-9.4-9.3-18.5-18.9-28.7-27.3-4.2-3.5-10.3 2.6-6.1 6.1z" />
    </g>
  </svg>
)

export default CompteNewSvg
